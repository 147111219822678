import { isValidForm } from "./validation";
import { defaultHeaders, uriList, TOKEN_KEY, TOKEN_USER, TOKEN_CAPTCHA, TOKEN_BANK } from "./config";
import { criptografar, descriptografar, } from "../helpers/crypto";
import { saveCookie, getCookie, getLocation } from "../helpers/general";
import { getQueriesForElement } from "@testing-library/react";
import { swalError } from "../components/swal";

export function gravarIp() {
  let ip = getCookie({ label: "ip" });
  if (!ip || ip === "10") {
    fetch("https://api.ipify.org?format=json")
      .then((res) => res.json())
      .then((data) => {
        if (data.ip) saveCookie({ label: "ip", content: data.ip });
        // if (!data.ip) throw new Error();
        // else {
        //     saveCookie({ label: 'ip', content: data.ip });
        // }
      })
      .catch((e) => {
        saveCookie({ label: "ip", content: "10" });
      });
  }
}

const getUser = (content) => {
  // console.log("getUser", content);
  // let _user = localStorage.getItem(TOKEN_USER);
  let _user = getItemCrypt(TOKEN_USER);
  // console.log("getUser(1): ", _user);
  if (_user && content) {
    let user = JSON.parse(_user);
    // console.log("getUser(2)");
    content["userid"] = user?.id;
    // console.log("getUser(3)");
  }
};

const _getGeolocation = (content) => {
  let [latitude, longitude] = getCookie({ label: "geolocation" })
    ? getCookie({ label: "geolocation" }).split(",")
    : ["", ""];
  if (!content?.latitude && latitude) {
    content.latitude = latitude;
    content.longitude = longitude;
  }
  return { latitude, longitude };
};

const getGeolocation = (content) => {
  let result = content;
  if (content && !content.latitude) {
    let [latitude, longitude] = getCookie({ label: "geolocation" })
      ? getCookie({ label: "geolocation" }).split(",")
      : ["", ""];
    if ((!latitude || latitude == "") && navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
      });
    }

    result.latitude = latitude;
    result.longitude = longitude;
    content.latitude = latitude;
    content.longitude = longitude;
  }
  return result;
};

export const setSession = ({ obj, key }) => {
  let content = JSON.stringify(obj);

  // console.log("setSession - content: ", content)
  // console.log("setSession - key: ", key)
  localStorage.setItem(key, content);
};

export const getSession = ({ key }) => {
  if (typeof window !== "undefined") {
    let result = JSON.parse(localStorage.getItem(key));

    // console.log("getSession", result)
    return result;
  }

  return false;
};

export const parseComplexJson = () => {
  let complex = localStorage;
  let complexKeys = Object.keys(localStorage);

  let obj = {};

  for (let key of complexKeys) {
    obj[key] = JSON.parse(complex[key]);
  }

  return obj;
};

export const singleJSONTranspile = () => {
  let json = parseComplexJson();
  let obj = {};

  for (let j in json) {
    for (let d in json[j]) {
      obj[d] = json[j][d];
    }
  }

  return obj;
};

export const clearSession = () => {
  const token = getItemCrypt(TOKEN_KEY);
  const user = getItemCrypt(TOKEN_USER);
  const _bancoFornecedor = getItemCrypt(TOKEN_BANK);
  const cpf = localStorage.getItem(criptografar("CPF"));

  localStorage.clear();

  setItemCrypt(TOKEN_KEY, token);
  setItemCrypt(TOKEN_USER, user);
  setItemCrypt(TOKEN_BANK, _bancoFornecedor);
  localStorage.setItem(criptografar("CPF"), cpf);
};

export const getProposta = async (uuid) => {
  if (uuid) {
    let body = await fetch(uriList("acompanharProposta"), {
      body: JSON.stringify({ uuid, bancoFornecedor: bancoFornecedor() }),
      method: "POST",
      headers: new Headers(defaultHeaders()),
    });

    let response = await body.json();
    return response;
  }
};

export const buscarAutorizacao = async (dados) => {
  console.log("auth.buscarAutorizacao: ", dados);
  console.log("buscarAutorizacao: ", dados);
  let result = true;
  // if (dados.tipo_convenio == "INSS") {
  //   await sendData({
  //     uri: uriList("buscarAutorizacao"),
  //     content: dados,
  //     method: "POST",
  //   })
  //     .then((res) => {
  //       if (res.status && res.status > 200) {
  //         swalError({ title: "Erro ao obter Autorização", text: res.message, });
  //         console.log("buscarAutorizacao - res(response): ", res);
  //         result = false;
  //       }

  //       console.log("buscarAutorizacao - res: ", res.data);
  //       if (res.data && res.data.tipo) result = true;
  //     })
  //     .catch((e) => { });
  // }
  // else {
  await sendData({
    uri: uriList("checarBloqueio"),
    content: dados,
    method: "POST",
  })
    .then((res) => {
      if (res.status && res.status > 200) {
        swalError({ title: "Erro ao obter Autorização", text: res.message, });
        console.log("buscarAutorizacao - res(response): ", res);
        result = false;
      }
      else {
        console.log("buscarAutorizacao - res: ", res.data);
        result = true
      }

    })
    .catch((e) => { });
  // }

  return result;
};

export const getData = async ({ uri, signal, content }) => {
  try {
    let send = {
      signal,
      headers: new Headers(defaultHeaders()),
    };
    if (content) send["body"] = { ...content, bancoFornecedor: bancoFornecedor() };

    let body = await fetch(uri, send);

    let response = await body;

    return response.json();
  } catch (error) {
    console.log(error);
  }
};

export const pureGetData = async ({ uri, signal }) => {
  let body = await fetch(uri, {
    signal,
  });

  let response = await body;

  return response.json();
};

export const getTextData = async ({ uri, signal }) => {
  let body = await fetch(uri, {
    signal,
    headers: new Headers(defaultHeaders()),
  });

  let response = await body;

  return response.text();
};

export const gravarEtapaProposta = async (content) => {
  let result = true;
  await sendData({
    uri: uriList("gravarEtapas"),
    content,
    method: "POST",
  })
    .then((res) => {
      if (res.status && res.status > 200) {
        console.log("buscarAutorizacao - res: ", res.data);
        if (res.data && res.data.tipo) result = false;
      }
    })
    .catch((e) => { });

  return result;
};

export const sendData = async ({ uri, content, signal, method }) => {
  console.log("sendData - uri: ", uri);
  let response;
  if (!content) content = {};
  _getGeolocation(content);
  console.log("sendData - uri(0) ");
  getUser(content);
  content.bancoFornecedor = bancoFornecedor();
  //   console.log("sendData - uri(0.1) ");
  // console.log("sendData - content: ", content);
  if (!content.ip) content.ip = getCookie({ label: "ip" });

  console.log("sendData(1): ", { uri, content });
  try {
    let result = await fetch(uri, {
      body: JSON.stringify(content),
      method,
      headers: new Headers(defaultHeaders()),
    });

    response = await result;
    let status = response.status;
    response = await response.json();
    response["_status"] = status;
    // console.log("sendData - content(1): ", response)
  } catch (err) {
    console.log("sendData(1) - error: ", err);
    // console.log("Dados: \n", content)
    // console.log("uri: \n", uri)
    // console.log("Erro: \n", err)
    response["_status"] = 401;
  }

  return response;
};

export const sendMultiPartData = async ({ uri, content, signal, method, errorMsg, errorTitle }) => {
  const _headers = {
    Accept: "*",
    "Access-Control-Allow-Origin": "true",
    key: process.env.REACT_APP_KEY || "aZcVK2kl4M1f4jRVtoRPToZy3jl1qUzY",
    // Authorization: `bearer ${localStorage.getItem(TOKEN_KEY)}`,
    Authorization: `bearer ${getItemCrypt(TOKEN_KEY)}`,
    "captcha": getItemCrypt(TOKEN_CAPTCHA),
    // "Content-Type": "multipart/form-data",
    token: "",

  }
  console.log("auth.sendMultiPartData: ", { uri, content, signal, method, _headers });
  if (content)
    content.bancoFornecedor = bancoFornecedor()

  try {
    _getGeolocation(content);
    let body = await fetch(uri, {
      body: content,
      method,
      headers: new Headers(_headers),
    });

    let response = await body;
    return response.json();
  } catch (e) {
    if (errorMsg)
      swalError({ title: errorTitle || "Erro ao executar ação", text: errorMsg, })
    else
      throw e;
  }
};

export const sendForm = (data) => {
  let valid;
  let newData = data;

  if (data) {
    delete newData.range;

    valid = isValidForm(newData);

    if (!valid[0]) {
      return [false, valid[1]];
    }
  }

  return [true, ""];
};

function logout() {
  console.log("!!!!! Tah na hora de deslogar !!!!!");
  localStorage.clear();
  // if (rest && rest.alterarUser)
  //   rest.alterarUser(null)
  window.location.reload();
}

export const inactivityTime = (ini = false) => {
  if (localStorage.getItem(TOKEN_KEY) !== null) {
    let TIME_INATIVITY = 20; //-- Tempo de inatividade em minutos
    var time;

    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onkeypress = resetTimer;
    window.onscroll = resetTimer;
    window.onkeydown = resetTimer;

    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(logout, 60000 * TIME_INATIVITY);
    }

    localStorage.setItem("inactivityTime", true);

    if (ini) resetTimer();

    return time;
  }
};

export const getItemCrypt = (key, forcaLogout = false) => {
  // console.log("getItemCrypt - key: ", key)
  let result = localStorage.getItem(key);
  if (result)
    result = descriptografar(result);

  // console.log("criptGetItem - result: ", result)
  if (!result && forcaLogout) logout();

  return result;
}

export const bancoFornecedor = () => {
  const _bancoFornecedor = process.env.REACT_APP_BANCO_FORNECEDOR || getItemCrypt(TOKEN_BANK)?.toString()?.toLowerCase() || "brb"
  console.log("auth.bancoFornecedor: ", _bancoFornecedor)

  return _bancoFornecedor
}

export const setItemCrypt = (key, value) => {
  const criptedValue = criptografar(value)
  const descriptedValue = descriptografar(criptedValue)
  let result = localStorage.setItem(key, criptedValue);

  console.log("criptSetItem - result: ", { key, value, criptedValue, descriptedValue })
  return result;
}

export const removeItemCrypt = (key) => {
  let result = localStorage.removeItem(key);
  return result;
}

