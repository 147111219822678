import React, { Component } from "react";
import { Link } from "react-router-dom";
import NotificationMenu from "./Menus/notificationMenu";
import ProfileMenu from "./Menus/profileMenu";
import LogoTransp from "../../assets/logo-transparente1.png";
import ImageSide from '../../assets/colmeia_logo.png';
import { width } from "../../helpers/general";
import { getItemCrypt, bancoFornecedor } from "../../helpers/auth";
import { TOKEN_BANK } from "../../helpers/config";

// import LogoInbursa from "../../images/logo-inbursa-org2.png";
import LogoInbursa from "../../images/logo-inbursa-org.png";
import LogoBrb from "../../images/logo-brb-transparent.png";
// import LogoCEF from "../../images/logo-cef-transparent.png";
import LogoCEF from "../../images/CAIXA_volume_negativa.png";

import { connect } from "react-redux";

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtop: false,
    };

    this.searchtoggle = this.searchtoggle.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.urlUserLogo = props.user?.url_logo;
    console.log("TopBar.width: ",)
    //   "https://incontadig.s3.amazonaws.com/2022/7/27/af87ac42-96a5-4b26-967a-aebc60ffa700/9531exfs8u4s40zxw1j28_1658948945782.png";
  }

  searchtoggle() {
    this.setState({
      searchtop: !this.state.searchtop,
    });
  }

  toggleSidebar() {
    document.body.classList.toggle("fixed-left-void");
    document.getElementById("wrapper").classList.toggle("enlarged");
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    return (
      <>
        <div className="topbar">
          <nav className="navbar-custom ">
            <ul className="list-inline float-right mb-0">
              <li className="list-inline-item dropdown notification-list hide-sm  mr-2 ml-1">
                <span
                  className="nav-link waves-effect"
                  onClick={this.toggleFullscreen}
                  id="btn-fullscreen"
                >
                  <i className="mdi mdi-fullscreen noti-icon"></i>
                </span>
              </li>

              <NotificationMenu />

              <ProfileMenu />
            </ul>

            <button
              type="button"
              onClick={this.toggleSidebar}
              className="button-menu-mobile open-left waves-effect"
            >
              <i className="mdi mdi-menu"></i>
            </button>
            {!this.urlUserLogo && ["inbursa", "cef"].indexOf(bancoFornecedor()) < 0 &&
              <img
                src={LogoTransp}
                style={{
                  width: width() !== "mobile" ? "200px" : "100px",
                  boxShadow: "0.1px 0.1px  #08899E"
                }}

                alt=""
              />}
            {this.urlUserLogo && ["inbursa", "cef"].indexOf(bancoFornecedor()) < 0 && (
              <img
                src={this.urlUserLogo}
                style={{
                  width: width() !== "mobile" ? "200px" : "100px",
                  marginLeft: width() !== "mobile" ? "20px" : "",
                  boxShadow: width() !== "mobile" ? "0.1px 0.1px  #08899E" : ""
                }}
                className="rounded-logo"
                alt=""
              />
            )}

            {width('', true) > 375 && ["inbursa", "cef"].indexOf(bancoFornecedor()) < 0 &&
              <a href="https://instagram.com/colmeia_oficial_?igshid=YmMyMTA2M2Y="
                target="_blank" rel="noopener noreferrer" style={{ color: "#08899E" }}>
                <img
                  src={ImageSide}
                  style={{
                    width: width() !== "mobile" ? "55px" : "25px",
                    marginLeft: width() !== "mobile" ? "10px" : "5px",
                    marginBottom: width() !== "mobile" ? "10px" : "",
                    marginTop: width() !== "mobile" ? "10px" : "",
                  }}
                  className="rounded-logo"
                  alt=""
                  title="Clique e entenda mais..."
                />
              </a>}
            {width('', true) > 375 &&
              <a href={bancoFornecedor() == "inbursa" ? "https://www.inbursa.com/" : bancoFornecedor() == "cef" ? "https://www.cef.com.br/" : "https://www.brb.com.br/"}
                target="_blank" rel="noopener noreferrer" style={{ color: "#08899E" }}>
                <img
                  src={bancoFornecedor() == "inbursa" ? LogoInbursa : bancoFornecedor() == "cef" ? LogoCEF : LogoBrb}
                  // style={{
                  //   width: width() !== "mobile" ? "100px" : "100px",
                  //   marginLeft: width() !== "mobile" ? "10px" : "5px",
                  //   marginBottom: width() !== "mobile" ? "10px" : "",
                  //   marginTop: width() !== "mobile" ? "10px" : "",
                  //   backgroundColor: 'transparent !important',
                  // }}
                  style={{
                    width: width() !== "mobile" ? (bancoFornecedor() == "inbursa" ? "200px" : "120px") : "100px",
                    marginLeft: width() !== "mobile" ? "20px" : "",
                    boxShadow: width() !== "mobile" ? "0.1px 0.1px  #08899E" : ""
                  }}
                  className="rounded-logo"
                  alt=""
                  title="Clique e entenda mais..."
                />
              </a>}

          </nav>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(Topbar);
