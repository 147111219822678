import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button } from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import Swal, { swalError, confirmSwal } from "../../../swal";
import { sendData, getData, bancoFornecedor } from "../../../../helpers/auth";
import { Aguarde, width } from "../../../../helpers/general";
import { getItemCrypt } from '../../../../helpers/auth'
import { uriList, TOKEN_KEY, TOKEN_CAPTCHA, TOKEN_BANK } from "../../../../helpers/config";
import { validateEmail, validateCPF } from "../../../../helpers/validation";
import { Pagination, FilterSelect, InputLabelAlert } from "../../../layout";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { mCPF } from "../../../../helpers/general";
import Dropzone from "react-dropzone";
import { post } from "axios";
import { connect } from "react-redux";
import swal from "sweetalert";
import Permissoes from './usuariosacl'

let dataIni = {
  name: "",
  email: "",
  password: null,
  tipo: "OPERADOR PARCEIRO",
  cpf: "",
  pessoa_id: 0,
  empresa_id: 0,
  id: 0,
  status: "ATIVO",
  celular: null,
  telefone: null,
};

let warningIni = {
  email: 0,
  password: 0,
  name: 0,
  cpf: 0,
};

let tipos = [
  {
    id: "CLIENTE",
    nome: "Cliente",
  },
  {
    id: "OPERADOR",
    nome: "Operador",
  },
  {
    id: "AGENTE",
    nome: "Agente",
  },
  {
    id: "OPERADOR PARCEIRO",
    nome: "Operador Parceiro",
  },
  {
    id: "ADMINISTRADOR",
    nome: "Administrador",
  },
];

let statuses = [
  { id: "ATIVO", nome: "Ativo" },
  { id: "INATIVO", nome: "Inativo" },
];

const tiposCredito = [
  { id: "TODOS", nome: "Todos", value: "TODOS" },
  { id: "CONSIGNADO", nome: "Consignado", value: "CONSIGNADO" },
  { id: "PESSOAL", nome: "Pessoal", value: "PESSOAL" },
  { id: "EMPRESARIAL", nome: "Empresarial", value: "EMPRESARIAL" },
];

const Form = ({ ...props }) => {
  let {
    filterOptions,
    handleOption,
    usuarios,
    usuariosG,
    setClickedTr,
    sortArrObj,

    actualPage,
    handlePage,
    handleQtdLinesPage,
    totalPages,

    clickedTr,
    callback,
  } = props;

  const [data, setData] = useState({ status: "ATIVO", password: null });
  const [warning, setWarning] = useState(warningIni);
  const [disableName, setDisableName] = useState(false);
  const [method, setMethod] = useState("POST");
  const [show, setShow] = useState(false);
  const [gravandoLogo, setGravandoLogo] = useState(false);
  const [gravando, setGravando] = useState(false);
  const [permissoes, setPermissoes] = useState(false);
  const [usuarioAtual, setUsuarioAtual] = useState(null);
  const [empresas, setEmpresas] = useState(null);
  const [qtdLinesPage, setQtdLinesPage] = useState(10);
  const tableRef = useRef(null);

  const arrayPagination = [10, 20, 30, 40, 50, 100, 500, 1000, 5000, 10000]

  const _handleQtdLinesPage = (e) => {
    const value = e?.currentTarget?.value
    console.log("usuarios._handleQtdLinesPage: ", value)
    handleQtdLinesPage(value)
    setQtdLinesPage(value)
  }
  const handleEdit = async (_usuario) => {
    console.log("usuarios.handleEdit", _usuario);
    let result = true
    // setPessoas(pessoasG);
    setWarning(warningIni);
    setData({ ...dataIni, empresa_id: props.user?.empresa_id });
    getData({ uri: uriList("usuarios") + "/" + _usuario.id })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();

        let _data = {
          name: res.name,
          email: res.email,
          tipo: res.tipo,
          cpf: res.cpf,
          pessoa_id: res.pessoa_id,
          pessoaid: res.pessoaid,
          id: res.id,
          password: null,
          status: res.status,
          tipo_credito: res.tipo_credito,
          usermst_id: res.usermst_id,
          supervisor_id: res.supervisor_id,
          usermst: res.usermst,
          padrao: res.padrao,
          url_logo: res.url_logo,
          procreditoid: res.procreditoid,
          observacao: res.observacao,
          empresa_id: res.empresa_id,
          celular: res.celular,
          telefone: res.telefone
        };
        setData(_data);
        setUsuarioAtual(_data)
        setShow(true);
        setMethod("EDIT");
        console.log("usuarios.handleEdit(user): ", _data)
      })
      .catch((e) => {
        console.log("handleEdit(eror): ", e.message);
        result = false;
      });

    return result
  };

  const handleNew = () => {
    setWarning(warningIni);
    // setPessoas(pessoasG.filter((pessoa) => !pessoa.user_id));
    // setData(dataIni);
    setData({ ...dataIni, empresa_id: props.user?.empresa_id });
    setMethod("NEW");
    setShow(true);
  };

  useEffect(() => {
    // setData(dataIni);
    setData({ ...dataIni, empresa_id: props.user?.empresa_id });

    // buscarPessoas();
  }, []);

  const _empresas = useCallback(() => {
    console.log("Usuarios._empresas(useCallback)")
    getData({ uri: uriList("empresas") })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();

        setEmpresas(res);
      })
      .catch((e) => {
        return false;
      });
  });

  useEffect(() => {
    _empresas();
  }, []);

  async function handle(e) {
    let target = e.currentTarget;
    let name = target.name;
    let value = target.value;
    // console.log("usuarios.handle(1): ", name, value);
    if (value?.toString() == "Selecione um usuário...") value = null;

    setWarning({ ...warning, [name]: 0 });
    if (name == "name") value = value.toUpperCase();

    if (name === "cpf") {
      if (value?.length <= 14) {
        setData({ ...data, [name]: mCPF(e.currentTarget.value) });
      }

      if (value?.length >= 14) {
        if (!validateCPF(value)) { setWarning({ ...warning, cpf: 1 }); }
        else {
          console.log("usuarios.handle(2)")
          let _user = (await getUser(value))?.[0];
          console.log("usuarios.handle(3): ", _user)
          if (_user) {
            setWarning({ ...warning, cpf: 1 });
            swalError(`Usuário já cadastrado: ${_user?.name}`)
          }
        }
      }
      // setWarning({ ...warning, cpf: 1 })
    } else {
      setData({
        ...data,
        [name]: name == "email" ? value.toLowerCase() : value,
      });
    }
  }

  async function getUser(cpf) {
    let uri = uriList("buscarUsuario");
    let result = false;
    await sendData({ uri, content: { cpf }, method: "POST" })
      .then((res) => {
        if (res._status == 200 && res.data)
          result = res.data;
      })
      .catch((e) => {
        console.log("usuarios.validarEmail(OK)");
      });

    return result;
  }

  function handleClose(e, close) {
    if (e) e.preventDefault();

    setShow(false);
    if (props.onCloseEv) props.onCloseEv(close);

    if (props.callback) props.callback();

    // if (method == "NEW") buscarPessoas();
  }

  async function validarDados() {
    let result = true;
    let _warning = {}

    console.log("usuarios.validarDados: ", data);
    if (!data.password && method == "NEW") {
      setWarning({ ..._warning, password: 1 });
      return false;
    }

    if (!data.email) {
      setWarning({ ..._warning, email: 1 });
      return false;
    }

    result = await validarEmail(data.email)
    if (!result) {
      setWarning({ ..._warning, email: 1 });
      return false
    }

    if (method == "NEW") {
      if (!data.cpf) {
        setWarning({ ..._warning, cpf: 1 });
        return false
      }

      if (!data.name) {
        setWarning({ ..._warning, name: 1 });
        return false
      }

      result = await validarCPF(data.cpf)
      console.log("usuarios.validarDados(validarCPF): ", result);
      if (!result) {
        setWarning({ ..._warning, cpf: 1 });
        return false
      }
    }

    setWarning(_warning);
    console.log("usuarios.validarDados: ", _warning);

    return result;
  }

  const handleHabilitar = async (e) => {
    let result = true
    let send = {
      uri: uriList("habilitarUsuario"),
      content: data,
      signal: null,
      method: "POST",
    };
    await sendData(send)
      .then((res) => {
        result = true;
      })
      .catch((e) => {
        console.log(e);
        result = false;
      });

    return result
  }

  async function handlePost(e) {
    e.preventDefault();
    console.log("usuarios.handlePost: ", { data, usuarioAtual });

    async function callback(res) {
      let result = true
      if (!res)
        return false

      setGravando(true)
      let _data = data;
      delete _data.id;
      // if (method == "EDIT")
      //   delete _data.password;

      let send = {
        uri: uriList("gravarUsuario"),
        content: _data,
        signal: null,
        method: "POST",
      };
      await sendData(send)
        .then(async (res) => {
          console.log("usuarios.handlePost(2): ", res);
          if (res?.status > 200) {
            swalError(res.message ? res?.message : "Erro ao gravar usuário!");
            result = false;
          }

          //-- Executando endpoint para habilitar o usuário
          if (props?.user?.tipo == 'ADMINISTRADOR')
            await handleHabilitar(e);

          setGravando(false);
          handleClose();
          result = true;
        })
        .catch((e) => {
          console.log(e);
          result = false;
          setGravando(false);
        });

      setGravando(false);
      return result
    }

    const _validarDados = await validarDados();
    if (_validarDados) {
      console.log("usuarios.handlePost(1) ");

      //-- Exibindo mensagem caso usuário a ser salvo seja do tipo Operador ou Administrador
      if (data?.usermst_id && usuarioAtual?.usermst_id && data?.usermst_id != usuarioAtual?.usermst_id) {
        confirmSwal({ title: "O Usuário Master será alterado, deseja prosseguir?", text: "A produção até a presente data vinculada a este agente será migrada para o master anterior.", callback, changeOrderButtons: true })
      } else if (['ADMINISTRADOR', 'OPERADOR'].indexOf(data.tipo) >= 0)
        confirmSwal({ title: `O Usuário está sendo cadastrado como: ${data.tipo}, continua?`, text: "O tipo de usuário pode afetar a visualização e utilização do sistema.", callback, changeOrderButtons: true })
      else
        callback(true)
    } else {
      if (warning.cpf && warning?.msgErro)
        swalError(`Usuário já cadastrado: ${warning?.msgErro}`);
      else
        swalError("Favor informar os dados corretamente!");
    }
  }

  //-- Funcao valida o email junto ao banco de dados(se já existe pessoa ou usuário)
  async function validarEmail() {
    console.log("usuarios.validarEmail(1): ", data.email);
    const _valid = validateEmail(data.email)
    console.log("usuarios.validarEmail(2): ", _valid);
    if (!_valid) {
      setWarning({ ...warning, email: 1 });
      return false
    }

    if (method == "NEW" && data.email) {
      let result = true
      console.log("usuarios.validarEmail(2)");
      //-- Validando se o conteudo do campo email é válido      

      let uri = uriList("buscarUsuario");
      await sendData({ uri, content: { email: data.email }, method: "POST" })
        .then((res) => {
          console.log("usuarios.validarEmail(buscarUsuario - Existe): ", res);
          if (res._status == 200) {
            setWarning({ ...warning, email: 1 });
            result = false
          }
        })
        .catch((e) => {
          console.log("usuarios.validarEmail(OK)");
        });

      return result
    }

    return true
  }

  async function validarCPF(_cpf) {
    const cpf = (_cpf || data.cpf).replace(/[^\d]+/g, '');
    console.log("usuarios.validarCPF(1): ", cpf);
    if (!cpf)
      return false

    if (!validateCPF(cpf)) {
      setWarning({ ...warning, cpf: 1 });
      return false
    }

    if (method == "NEW" && cpf) {
      let result = true
      console.log("usuarios.validarCPF(2) ");
      //-- Validando se o conteudo do campo email é válido
      let uri = uriList("buscarUsuario");
      await sendData({ uri, content: { cpf: cpf }, method: "POST" })
        .then(async (res) => {
          console.log("usuarios.validarCPF(buscarUsuario - Existe): ", res);
          if (res._status == 200) {
            setWarning({ ...warning, cpf: 1 });
            await swalError({ title: `Usuário já cadastrado: ${res?.data?.nome || res?.data?.name}`, promise: true });
            result = false
          }
        })
        .catch((e) => {
          console.log("usuarios.validarCPF(OK)");
        });

      return result
    }

    return true
  }

  function enviarLogo(file) {
    console.log("Usuarios.enviarLogo(1): ", file)
    if (!data?.email)
      return false

    setGravandoLogo(true)
    let dObj = new FormData();
    dObj.append("email", data?.email);
    dObj.append("file", file);
    dObj.append("bancoFornecedor", bancoFornecedor())
    const url = uriList("uploadLogo");
    const config = {
      headers: {
        Accept: "*",
        key: process.env.REACT_APP_KEY || "aZcVK2kl4M1f4jRVtoRPToZy3jl1qUzY",
        "content-type": "multipart/form-data",
        "Authorization": `bearer ${getItemCrypt(TOKEN_KEY)}`,
        "captcha": getItemCrypt(TOKEN_CAPTCHA),
      },
    };

    post(url, dObj, config)
      .then((response) => {
        if (response?.status > 200)
          console.log("usuarios.enviarLogo(envio com erro)");
        setData({ ...data, url_logo: file?.preview })
        setGravandoLogo(false)
      })
      .catch((err) => {
        console.log("usuarios.enviarLogo(error): ", err);
        setGravandoLogo(false)
      });
  }

  function excluirLogo() {
    if (!data?.email)
      return false

    async function callback(e) {
      let result = true
      if (e && data?.email) {
        console.log("Usuarios.excluirLogo(e): ", e)
        await sendData({
          uri: uriList("dropLogo"),
          content: { email: data?.email },
          method: "POST",
        })
          .then((res) => {
            if (res.status && parseInt(res.status) > 200) throw new Error();
            // alert('Usuario excluído com sucesso!')
            // callback();
            setData({ ...data, url_logo: "" })
          })
          .catch((e) => {
            result = false
            alert("Erro ao excluir Logo do Usuário.");
          });

        return result
      }
    }

    confirmSwal({ callback, title: "Confirma a exclusão da logo/imagem?" })
  }

  function handlePermissoes(usuario) {
    setPermissoes(usuario)
  }

  return (
    <>
      {permissoes && <Permissoes usuario={permissoes} onCloseEv={() => setPermissoes(false)} />}
      {gravandoLogo &&
        <Aguarde legenda={"Aguarde, gravando logo..."} />}
      {!props.loaded &&
        <Aguarde legenda={"Aguarde, carregando dados..."} />}
      {gravando &&
        <Aguarde legenda={"Aguarde, gravando dados..."} />}
      <Modal
        size="lg"
        show={show}
        centered
        onHide={(e) => {
          handleClose(e, true);
        }}
        animation={false}
        backdrop="static"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Cadastro de Usuários</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="maxWidth650">
          <div style={{ display: "grid", }}>
            <div className="form-group">
              <label style={{ fontSize: "1em" }} htmlFor="email">
                Email
              </label>
              <input
                type="email"
                className={`form-control ${warning.email ? "is-invalid" : ""}`}
                id="email"
                name="email"
                required={true}
                disabled={method == "EDIT"}
                autoFocus={method == "NEW"}
                value={data.email}
                onChange={handle}
                onBlur={validarEmail}
                placeholder="Ex.: exemplo@exemplo.com"
              />

              <div className="invalid-feedback">
                Email inválido ou já cadastrado!
              </div>
            </div>

            {method == "NEW" && (!data.pessoa_id || data.pessoa_id == "0") && (
              <div>
                <div className="form-group">
                  <label style={{ fontSize: "1em" }} htmlFor="name">
                    Cpf
                  </label>
                  <input
                    type="tel"
                    className={`form-control ${warning.cpf ? "is-invalid" : ""
                      }`}
                    id="cpf"
                    name="cpf"
                    required={true}
                    disabled={disableName}
                    value={data.cpf}
                    onChange={handle}
                    onBlur={(e) => validarCPF(e.currentTarget.value)}
                  />
                  <div className="invalid-feedback">
                    CPF inválido ou já cadastrado!
                  </div>
                </div>

              </div>
            )}

            <div className="row">
              <div className="form-group col-md-6">
                <label style={{ fontSize: "1em" }} htmlFor="name">
                  Nome
                </label>
                <input
                  type="text"
                  className={`form-control ${warning.name ? "is-invalid" : ""
                    }`}
                  id="name"
                  name="name"
                  required={true}
                  disabled={disableName}
                  value={data.name}
                  onChange={handle}
                />
                <div className="invalid-feedback">O Nome é obrigatório!</div>
              </div>
              <div className="form-group col-md-3">
                <label style={{ fontSize: "1em" }} htmlFor="celular">
                  Celular
                </label>
                <input
                  type="text"
                  className={`form-control ${warning.name ? "is-invalid" : ""}`}
                  id="celular"
                  name="celular"
                  required={true}
                  // disabled={disableName}
                  value={data.celular}
                  onChange={handle}
                />
              </div>
              <div className="form-group col-md-3">
                <label style={{ fontSize: "1em" }} htmlFor="telefone">
                  Telefone
                </label>
                <input
                  type="text"
                  className={`form-control ${warning.name ? "is-invalid" : ""}`}
                  id="telefone"
                  name="telefone"
                  required={true}
                  // disabled={disableName}
                  value={data.telefone}
                  onChange={handle}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <label className="m-0 font-90">Senha</label>
                <input
                  type="password"
                  className={`form-control ${warning.password ? "is-invalid" : ""}`}
                  id="password"
                  name="password"
                  required={true}
                  autoFocus={method == "EDIT"}
                  placeholder="Digite sua Senha"
                  value={data.password}
                  onChange={handle}
                  autoComplete="off"
                // preventDefault={true}
                // disabled={method == "EDIT"}
                />

                <div className="invalid-feedback">A senha é obrigatória!</div>
              </div>
              <div className="col-md-6">
                <label className="m-0 font-90">Tipo de Usuário</label>
                <select
                  id={"tipo"}
                  name={"tipo"}
                  required={true}
                  defaultValue={data.tipo}
                  className="form-control"
                  onChange={handle}
                >
                  {tipos &&
                    tipos.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.nome}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <label className="m-0 font-90">Tipo de Acesso</label>
                <select
                  id={"tipo_credito"}
                  name={"tipo_credito"}
                  required={true}
                  defaultValue={data.tipo_credito}
                  className="form-control"
                  onChange={handle}
                >
                  {tiposCredito &&
                    tiposCredito.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.nome}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-md-6">
                <label className="m-0 font-90">Status</label>
                <select
                  id={"status"}
                  name={"status"}
                  required={true}
                  defaultValue={data.status}
                  className="form-control"
                  onChange={handle}
                >
                  {statuses &&
                    statuses.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.nome}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <label className="m-0 font-90">Usuário Master</label>
                <select
                  id={"usermst_id"}
                  name={"usermst_id"}
                  required={true}
                  defaultValue={data.usermst_id}
                  className="form-control"
                  onChange={handle}
                >
                  <option key={null} value={null} >
                    {"Selecione um usuário..."}
                  </option>

                  {usuariosG &&
                    // usuariosLoc.filter((item) => item.id != data.id)
                    usuariosG.map((item, key) => {
                      return (
                        <option key={key} value={item.id} selected={data.usermst_id == item.id}>
                          {item.name + (width() != 'mobile' ? ' - ' + item.email : "")}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-md-6">
                <label className="m-0 font-90">Usuário Supervisor</label>
                <select
                  id={"supervisor_id"}
                  name={"supervisor_id"}
                  required={true}
                  defaultValue={data.supervisor_id}
                  className="form-control"
                  onChange={handle}
                >
                  <option key={null} value={null} >
                    {"Selecione um usuário..."}
                  </option>

                  {usuariosG &&
                    // usuariosLoc.filter((item) => item.id != data.id)
                    usuariosG.map((item, key) => {
                      return (
                        <option key={key} value={item.id} selected={data.supervisor_id == item.id}>
                          {item.name + (width() != 'mobile' ? ' - ' + item.email : "")}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className={`col-md-${props.user.gestor ? '6' : '12'}`}>
                <InputLabelAlert
                  type="tel"
                  label="ProcreditoID"
                  id="procreditoid"
                  cifrao={false}
                  value={data.procreditoid}
                  handle={handle}
                  onChange={handle}
                  focus={false}
                />
              </div>

              {props.user.gestor && empresas &&
                <div className="col-md-6">
                  <label className="m-0 font-90">Empresa</label>
                  <select
                    id={"empresa_id"}
                    name={"empresa_id"}
                    required={true}
                    defaultValue={data.empresa_id}
                    className="form-control"
                    onChange={handle}
                  >
                    {empresas &&
                      empresas.map((item, key) => {
                        return (
                          <option key={key} value={item.id} selected={data.empresa_id == item.id}>
                            {item.nome + (width() != 'mobile' ? ' - ' + item.cnpj : "")}
                          </option>
                        );
                      })}
                  </select>
                </div>
              }

              <div className="col-md-12 col-12">
                <label className="m-0 font-90">Observação</label>
                <textarea
                  type="textArea"
                  id="observacao"
                  label="observacao"
                  name="observacao"
                  rows="4"
                  className="form-control"
                  value={data.observacao}
                  onChange={handle}
                />
              </div>
            </div>

            {method != "NEW" &&
              <div className="row" style={{
                heigth: !data.url_logo ? "50px" : "",
              }}>
                <div className="col-md-12" style={{ cursor: "pointer", }}>
                  <label className="m-0 font-90">Logo/Imagem</label>
                  <div style={{
                    backgroundColor: !data.url_logo ? "white" : "#17a2b8", borderRadius: "10% !important",
                    alignContent: "center", alignItems: "center", display: "flex", justifyContent: "center",
                    border: "0.5px solid #E4E4E5",
                  }}>
                    {data.url_logo &&
                      <img
                        src={data.url_logo}
                        alt="Cloud-icon"
                        className="img-fluid custom-image d-block"
                        style={{ margin: "10px auto", }}
                        title="Clique para excluir a logo/imagem"
                        onClick={excluirLogo}
                      />}
                    {!data.url_logo &&
                      <Dropzone
                        style={{ width: "100%", heigth: "100%" }}
                        name={props.name}
                        onDrop={(acceptedFiles) => {
                          try {
                            // console.log("dropzone - ini");
                            let files = acceptedFiles.map((file, i) => {
                              let url = URL.createObjectURL(file);
                              let ext = file.name.split(".");
                              ext = ext[ext.length - 1];

                              file.preview = url;
                              file.ext = ext;

                              enviarLogo(
                                file,
                              );
                              return file;
                            });
                          } catch (e) {
                            // console.log("Erro ao fazer OnDrop....: ", e);

                          }
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section
                            className={
                              "drop-box"
                            }
                            style={{ width: "100%" }}
                          >
                            <div
                              {...getRootProps({
                                onFocus: (e) => (e.currentTarget.style.outline = "none"),
                              })}
                            >
                              <input
                                {...getInputProps({
                                  multiple: false,
                                  onClick: (e) => props.data && e.preventDefault(),
                                  accept: "image/jpg,image/jpeg,image/png",
                                  disabled: props.disabled,
                                })}
                              />

                              <h5 className="text-black text-center"
                                title="Clique para fazer upload da Logo/Imagem"
                              ><i className="mdi mdi-upload" />Clique para fazer Upload</h5>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    }
                  </div>
                </div>
              </div>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="success"
            onClick={handlePost}
            disabled={!props.loaded || warning?.email || !data.email || (method == "NEW" && warning?.cpf)}
          >
            Gravar
          </Button>
          {/* <Button
            color="primary"
            title="Fazer upload da Logo/Imagem"
            onClick={handlePost}
            disabled={!loaded || warning?.email}
          >
            <i className="mdi mdi-upload" />
          </Button> */}
          <Button
            color="secondary"
            onClick={(e) => {
              handleClose(e, true);
            }}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal >

      <div>
        <div className="col-md-4 py-1 px-0">
          <button
            onClick={() => handleNew()}
            className="btn btn-sm text-light"
            title="Novo Usuario"
            style={{ backgroundColor: "#00bdff", marginRight: "3px" }}
          >
            <i className="mdi mdi-plus-box" />
          </button>
          <button
            onClick={() => window.print()}
            className="btn btn-sm text-light font-weight-bold"
            title="Imprimir"
            style={{ backgroundColor: "#00bdff" }}
          >
            <i className="mdi mdi-printer" />
          </button>

          {/* <button
            onClick={() => props.handleClear()}
            className="btn btn-sm text-light"
            title="Limpar"
            style={{ backgroundColor: "#00bdff", marginLeft: "3px" }}
          >
            <i className="mdi mdi-filter-remove" />
          </button> */}
        </div>

        {<div className="row py-2">
          <div className={`col-md-${props?.user?.gestor ? '2' : '4'}`}>
            <InputLabelAlert
              className="m-0 font-90"
              type="text"
              label="Usuário"
              id="usuario_filter"
              cifrao={false}
              value={props.usuario_filter}
              handle={props.handleUsuario}
              focus={true}
            />
          </div>
          <div className={`col-md-2`}>
            <label className="m-0 font-90">Tipo</label>
            <select
              onChange={props.handleTipo}
              style={{
                lineHeight: "2.2em",
                fontSize: "0.8em",
              }}
              className="form-control"
              title="Filtrar Tipo de usuário"
              id="tipo"
            >
              <option value="todos" selected={!props.tipo || props.tipo == "todos"}>Todos</option>
              <option value="ADMINISTRADOR" selected={props.tipo == "ADMINISTRADOR"}>ADMINISTRADOR</option>
              <option value="AGENTE" selected={props.tipo == "AGENTE"}>AGENTE</option>
              <option value="OPERADOR" selected={props.tipo == "OPERADOR"}>OPERADOR</option>
              <option value="OPERADOR PARCEIRO" selected={props.tipo == "OPERADOR PARCEIRO"}>OPERADOR PARCEIRO</option>
              <option value="CLIENTE" selected={props.tipo == "CLIENTE"}>CLIENTE</option>
            </select>
          </div>
          <div className={`col-md-2`}>
            <label className="m-0 font-90">Usuário Master</label>
            <select
              onChange={props.handleMaster}
              style={{
                lineHeight: "2.2em",
                fontSize: "0.8em",
              }}
              className="form-control"
              title="Filtrar Usuários Master"
              id="master"
            >
              <option value="todos" selected={!props.master || props.master == "todos"}>Todos</option>
              <option value="sim" selected={props.master == "sim"}>Sim</option>
              <option value="nao" selected={props.master == "nao"}>Não</option>
            </select>
          </div>
          <div className={`col-md-2`}>
            <label className="m-0 font-90">Ocultar Inativos</label>
            <select
              onChange={props.handleInativos}
              style={{
                lineHeight: "2.2em",
                fontSize: "0.8em",
              }}
              className="form-control"
              title="Ocultar Usuários Inativos"
              id="ocultarInativos"
            >
              <option value="todos" selected={!props.ocultarInativos || props.ocultarInativos == "todos"}>Todos</option>
              <option value="sim" selected={props.ocultarInativos == "sim"}>Sim</option>
              <option value="nao" selected={props.ocultarInativos == "nao"}>Não</option>
            </select>
          </div>
          {props?.user?.gestor &&
            <div className={`col-md-2`}>
              <label className="m-0 font-90">Empresa</label>
              <select
                id={"selecaoEmpresa_id"}
                name={"selecaoEmpresa_id"}
                required={true}
                defaultValue={props.selecaoEmpresa_id}
                className="form-control"
                onChange={props.setSelecaoEmpresa_id}
              >
                <option value="todos" selected={!props.selecaoEmpresa_id || props.selecaoEmpresa_id == "todos"}>Todos</option>
                {empresas &&
                  empresas.map((item, key) => {
                    return (
                      <option key={key} value={item.id} selected={props.selecaoEmpresa_id == item.id}>
                        {item.nome + (width() != 'mobile' ? ' - ' + item.cnpj : "")}
                      </option>
                    );
                  })}
              </select>
            </div>}

          <div className={`col-md-2`} style={{ display: "flex", }}>
            <button
              onClick={props.handlePesquisar}
              style={{
                backgroundColor: "#17a2b8", color: "white", width: "100%",
                height: width() == "mobile" ? "100%" : "70%", marginTop: width() == "mobile" ? "10px" : "15px"
              }}
              title={"Clique aqui para realizar a busca/pesquisa dos usuários"}
              className="btn btn-sm "
            >
              <strong>Pesquisar</strong>
            </button>
            <button
              onClick={props.handleClearOptions}
              style={{ width: "100%", marginLeft: "5px", height: width() == "mobile" ? "100%" : "70%", marginTop: width() == "mobile" ? "10px" : "15px" }}
              className="btn btn-sm btn-secondary"
              title={"Clique aqui para limpar os filtros de pesquisa"}
            >
              Limpar
            </button>
          </div>
        </div>}

        <div className="table1 mt-4">
          < div className="mb-1" style={{
            border: "0.5px solid",
            borderColor: "lightGray",
            borderRadius: "2px",
            padding: "5px",
          }}>
            <DownloadTableExcel
              filename={`Tabela_Usuarios_pg: ${actualPage + 1}`}
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <button
                title={"Exportar tabela para excel"}
                className="mdi mdi-file-excel  ml-0 mb-1"
                style={{
                  border: "0.5px solid",
                  borderColor: "Green",
                  borderRadius: "2px",
                }}></button>
            </DownloadTableExcel>
            <table
              ref={tableRef}
              className="table table-bordered table-striped"
            >
              <thead>
                <tr
                  title="Clique para ordenar"
                  style={{ backgroundColor: "#f2f2f2" }}
                >
                  <th scope="col" className="font-80">
                    #
                  </th>
                  <th
                    scope="col"
                    className="font-80"
                    onClick={() => sortArrObj("nome", "string")}
                  >
                    Usuario
                  </th>
                  <th
                    scope="col"
                    className="font-80"
                    onClick={() => sortArrObj("email", "string")}
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="font-80"
                    onClick={() => sortArrObj("assunto", "string")}
                  >
                    Tipo
                  </th>
                  <th
                    scope="col"
                    className="font-80"
                    onClick={() => sortArrObj("status", "string")}
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="font-80"
                    onClick={() => sortArrObj("pessoa_nome", "string")}
                  >
                    Pessoa
                  </th>
                  <th
                    scope="col"
                    className="font-80"
                    onClick={() => sortArrObj("pessoa_nome", "string")}
                  >
                    Usuário Master
                  </th>
                </tr>
              </thead>
              <tbody>
                {usuarios?.length > 0 ? (
                  usuarios.map(
                    (usuario, i) =>
                    // i >= actualPage * 10 &&
                    // i < (actualPage + 1) * 10 && 
                    (
                      <tr
                        key={i}
                        className={`${clickedTr === usuario.id
                          ? "bg-secondary text-light"
                          : ""
                          }`}
                        onClick={() => {
                          setClickedTr(usuario.id);
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          setClickedTr(usuario.id);
                        }}
                        onDoubleClick={(e) => handleEdit(usuario)}
                      >
                        <td>{i + 1}</td>
                        <td>{usuario.name}</td>
                        <td>{usuario.email}</td>
                        <td>{usuario.tipo}</td>
                        <td>{usuario.status}</td>
                        <td>
                          {usuario.pessoa_nome +
                            (usuario.pessoa_cpf
                              ? " - " + usuario.pessoa_cpf
                              : "")}
                        </td>
                        <td>
                          {usuario.usermst_nome}
                        </td>
                        <td className="text-center" style={{ display: "flex", justifyContent: "space-evenly" }}>
                          <button
                            className="btn btn-sm btn-warning"
                            title="Altera um registro"
                            onClick={(e) => handleEdit(usuario)}
                          >
                            <i className="mdi mdi-file-edit-outline"></i>
                          </button>
                          <button
                            className="btn btn-sm btn-success"
                            title="Altera permissoes do usuário"
                            onClick={(e) => handlePermissoes(usuario)}
                          >
                            <i className="mdi mdi-account-key"></i>
                          </button>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td>Sem dados</td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="2" className="text-left">
                    Total de Usuários: {usuarios?.length}
                  </td>

                  <td colSpan="7"></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      < div className="d-flex" >
        <Pagination
          detailed={usuarios}
          actual={actualPage}
          changePage={handlePage}
          qtdLinesPage={qtdLinesPage}
          lastPage={totalPages}
        />

        <div className="page-item ml-1" style={{ padding: "0.05rem" }}>
          <select
            defaultValue={qtdLinesPage}
            name="qtdLinesPage"
            id="qtdLinesPage"
            onChange={_handleQtdLinesPage}
            style={{
              width: "75px",
              height: "100%",
              textAlign: "center",
              backgroundColor: "#EBEEE9",
              borderRadius: "4px",
              border: "1.5px solid #727476",
              backgroundImage:
                "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
            }}
          >
            {arrayPagination.map((lines, i) => (
              <option
                key={`lines-${i}`}
                label={lines}
                value={lines}
              >
                {lines}
              </option>
            ))}
          </select>
        </div>

      </div >
      {/* <Pagination
        detailed={usuarios}
        actual={actualPage}
        changePage={handlePage}
      /> */}
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(Form);


